const withAsync = async fn => {
  try {
    const response = await fn();
    return {
      response,
      error: null
    };
  } catch (error) {
    return {
      response: null,
      error
    };
  }
};

export default withAsync;
