class Api {
  post = async (url, body = {}, params = {}) => {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body),
      ...params
    });
    return response.json();
  };
}

export default new Api();
